var Pager = React.createClass({
  componentDidUpdate(){
  },
  render(){
    var stay = this.props.stay

    if( stay != 0 ){
      stay = stay - 1;
    }

    // var pager = this.props.max.map((index)=>{
    //   if( index === stay ){
    //     return (<li key={index} className="ExStay"><a href="#" data-num={index + 1} onClick={this.props.pagerClick}>{index + 1}</a></li>);

    //   }else{
    //     return (<li key={index}><a href="#" data-num={index + 1} onClick={this.props.pagerClick}>{index + 1}</a></li>);
    //   }
    // });

    var pager = ()=>{
      var list = [];
      for (var i = this.props.start; i < this.props.max; i++) {

        if( i === stay ){
          list.push(<li key={i} className="ExStay"><a href="#" data-num={i + 1} onClick={this.props.pagerClick}>{i + 1}</a></li>);

        }else{
          list.push(<li key={i}><a href="#" data-num={i + 1} onClick={this.props.pagerClick}>{i + 1}</a></li>);
        }

      }

      return list;
    }

    return (
      <ul className="MdPager01">
      {(() => {
        if ( this.props.nextback) {
          return <li className="ExBack"><a href="#" data-num={1} onClick={this.props.pagerClick}><span data-num={1} className="icon-icon02"></span></a></li>;
        }
      })()}
      {pager()}
      {(() => {
        if ( this.props.nextback) {
          return <li className="ExNext"><a href="#" data-num={this.props.last} onClick={this.props.pagerClick}><span data-num={this.props.last} className="icon-icon04"></span></a></li>;
        }
      })()}
      </ul>
    );
  }
});

// ReactDOM.render(
//   <Pager />,
//   document.getElementById('Gnav')
// );


module.exports = Pager;
var Store = require('./store-article');
var Pager = require('./comp-pager.jsx');
var CntsThumb = require('../pageFncs/cntsThumb.js');

var ArticleList = React.createClass({
  loadFlag: true,


  getDefaultProps(){
    return {
      actionType:"list"
    }
  },
  getInitialState(){
    return {
      nowPage: 0,
      maxPage: [],
      article: []
    }
  },
  start: 0,
  max: 0,
  offset: 5,
  nextback: false,
  componentWillMount(){
    this.loadAction();
  },
  loadAction(){

    this.loadFlag = false;

    if( Store.mainvisual.data === null && Store.gnav.data === null ){
      this.actionCreator( Store.PageControl.paramObjs.paged, [ this.props.actionType, 'mainvisual', 'gnav'] );
    }else if( Store.mainvisual.data === null && Store.gnav.data !== null ){
      this.actionCreator( Store.PageControl.paramObjs.paged, [ this.props.actionType, 'mainvisual'] );
    }else{
      this.actionCreator( Store.PageControl.paramObjs.paged, ['list'] );
    }

  },
  componentWillReceiveProps(){
  },
  actionCreator( page, comps ){

    Store.addSubscribe({
      actionType: this.props.actionType,
      callback: this.dataloaded
    });

    Store.dispatcher.action.create({
      actionType: this.props.actionType,
      page: page,
      callback: this.dataloaded,
      requireComps: comps
    });
  },
  dataloaded(){

    this.loadFlag = true;

    var _countArray = [];

    Store.removeSubscribe({
      actionType: this.props.actionType
    });

    for (var i = 0; i < Store.list.data.page.maxPage; i++) {
      _countArray.push(i);
    }

    this.imgLoading( Store.list.data.article ).then((e)=>{
      
      if( this.isMounted() ){
        this.replaceState({
          nowPage: Store.list.data.page.nowPage,
          maxPage: _countArray,
          article: Store.list.data.article
        });
      }
    });

  },
  componentDidMount(){
  },
  shouldComponentUpdate(){

    if( this.loadFlag ){
      return true;
    }else{
      return false;
    }

  },
  componentDidUpdate(){



    //$(window).scrollTop($('#FncListTtl').offset().top);


    if( !this.loadFlag ){
      return false;
    }

    //アップデート完了後にローディングを非表示
    Store.LoadControl.hidden();

    this.loadFlag = false;

    //高さ揃え
    CntsThumb.init(Store.Layout);

    $('.MdCntsThumb01 a').on('click', (e)=>{
      e.preventDefault();
    });

    if( Store.Layout === 'PC' ){
      $('.MdPager01 li').each(( i, elm)=>{
        $(elm).hover(
          ()=>{
            if( !$(elm).hasClass('ExStay') ){
              $(elm).addClass('ExHover01');
              $(elm).removeClass('ExHover02');
            }
          },
          ()=>{
            if( !$(elm).hasClass('ExStay') ){
              $(elm).addClass('ExHover02');
              $(elm).removeClass('ExHover01');
            }
          }
        );
      });
    }

    // loading用classを削除
    $('.MdPager01 .ExLoading').removeClass('ExLoading');

    if( Store.PageControl.paramObjs.pager ){
      if( Store.Layout === 'PC' ){
        $(window).scrollTop( $('#FncListTtl').offset().top -100 );
      }else if( Store.Layout === 'SP' ){
        $(window).scrollTop( $('#FncListTtl').offset().top -80 );
      }
    }

  },
  pagerClick( e ){

    e.preventDefault();

    Store.pagetype = 'pager';

    var _num = e.target.dataset.num;

    if( _num === '1'){
      _num = 0;
    }

    if( this.state.nowPage === _num ) {
      return false;
    }else{
      $(e.target).addClass('ExLoading');
      history.pushState(null,null,'/?type=' + Store.PageControl.paramObjs.type + '&paged=' + e.target.dataset.num + '&pager=true');
      Store.PageControl.getParam();
      this.actionCreator( _num, [ this.props.actionType ]);
    }

  },

  imgLoading( data ){
    var counter = 0,
        d = new $.Deferred();

    data.map((res, index)=>{
      let img = new Image();

      $(img).on('load', ()=>{
        counter = counter + 1;
        if( counter == data.length ){
          d.resolve(counter);
        }
      });

      $(img).attr('src', res.thumb);

    });

    return d.promise();

  },

  thumbClick( ID ){
    this.props.thumbClick(ID);
  },
  paging(){

    var nowPage,
        center = Math.round(this.offset / 2);

    if( this.state.nowPage === 0 ){
      nowPage = 1;
    }else{
      nowPage = this.state.nowPage;
    }

    // offset数より最大ページ数が多い場合はネクストバックボタンを表示
    if( this.state.maxPage.length > this.offset ){
      this.nextback = true;
    }

    if( this.state.maxPage.length <= this.offset ){
      this.max = this.state.maxPage.length;
      this.start = 0;
    }else if( nowPage <= center ){
      // ページャーの中心に到達までそのまま
      this.max = this.offset;
      this.start = 0;
    }else if( this.state.maxPage.length > ( nowPage - 1 ) + this.offset && nowPage > center ){
      // 表示ページ数のページ番号が半分以下の場合
      this.max = nowPage + center - 1;
      this.start = nowPage - center;
    }else if( this.state.maxPage.length > ( nowPage - 1 ) + this.offset && nowPage <= center ){
      // 表示ページ数のページ番号が半分以上の場合
      this.max = this.offset;
      this.start = 0;
    }else if( this.state.maxPage.length < nowPage + this.offset && ( nowPage - 1 ) + center < this.state.maxPage.length ){
      // 最終ページ数のページ番号が半分以下の場合
      this.max = nowPage + center - 1;
      this.start = nowPage - center;
    }else{
      this.max = this.state.maxPage.length;
      this.start = this.state.maxPage.length - this.offset;
    }
  },
  render(){

    if( !this.loadFlag ){
      return false;
    }else{

      // ページャーを設定
      this.paging();
      
      var article = this.state.article.map((res, i)=>{
        return (
            <section key={i} className="MdCntsThumb01"><a onClick={this.thumbClick.bind(this, res.ID)} href={'?type=single&paged='+res.ID}>
                <p className="mdImg"><img src={res.thumb} /></p>
                <div className="mdInfoClm">
                  <div className="mdClm01">
                    <p className="mdCat">{res.category}</p>
                  </div>
                  <div className="mdClm02">
                    <p className="mdDate">{res.date}</p>
                  </div>
                </div>
                <div className="mdInfoInBox">
                  <h2 className="mdTtl">{res.title}</h2>
                  <p className="mdTxt">{res.content}</p>
                </div>
                <p className="mdIcn"><span className="icon-icon04"></span></p>
                <div className="mdCover">
                  <p className="mdTxt">Read More</p>
                </div></a>
            </section>
          );
      });

      return (
        <section>
        <h2 id="FncListTtl" className="MdHdgCmn01"><span>All Contents</span></h2>
        <div className="LyCntsList">
        {article}
        </div>
        <Pager pagerClick={this.pagerClick} start={this.start} max={this.max} last={this.state.maxPage.length} nextback={this.nextback} stay={this.state.nowPage} />
        </section>
      );
    }
  }
});

module.exports = ArticleList;
var Store = require('./store-article');
var ArticleList = require('./comp-articleList.jsx');
var CategoryList = require('./comp-categoryList.jsx');
var TagList = require('./comp-tagList.jsx');
var Mainvisual = require('./comp-mainvisual.jsx');
var Gnav = require('./comp-gnav.jsx');
var Single = require('./comp-single.jsx');


var Page = React.createClass({
  getDefaultProps(){
    return {
    }
  },
  getInitialState(){
    return {
      pageType: 'index',
      pageID: null,
      update: false
    }
  },
  componentWillMount(){

    if( Store.PageControl.paramObjs.type === 'single' ){

      this.articleID = Store.PageControl.paramObjs.paged;

      this.setState({
        pageType: 'single',
        pageID: Store.PageControl.paramObjs.paged,
        update: false
      });

    }else if( Store.PageControl.paramObjs.type === 'category' ){
      this.articleID = Store.PageControl.paramObjs.name;

      this.setState({
        pageType: 'category',
        pageID: Store.PageControl.paramObjs.name,
        update: false
      });
    }else if( Store.PageControl.paramObjs.type === 'tag' ){
      this.articleID = Store.PageControl.paramObjs.paged;

      this.setState({
        pageType: 'tag',
        pageID: Store.PageControl.paramObjs.paged,
        update: false
      });
    }

    this.event();
  },
  articleID: null,
    actionCreator( article, comps ){

  },
  event(){
    $(window).on('popstate', ()=>{

      Store.PageControl.getParam();

      if( Store.PageControl.paramObjs.type === 'single' ){

        this.articleID = Store.PageControl.paramObjs.paged;

        this.replaceState({
          pageType: 'single',
          pageID: this.articleID
        });
      }else if( Store.PageControl.paramObjs.type === 'category' ){

        this.articleID = Store.PageControl.paramObjs.paged;

        this.replaceState({
          pageType: 'category',
          pageID: this.articleID
        });
      }else if( Store.PageControl.paramObjs.type === 'index' ){
        this.replaceState({
          pageType: 'index'
        });
      }else if( Store.PageControl.paramObjs.type === 'tag' ){
        this.articleID = Store.PageControl.paramObjs.paged;

        this.replaceState({
          pageType: 'tag',
          pageID: this.articleID
        });
      }
    });
  },
  thumbClick( ID ){

    Store.pagetype = 'article';

    $('.LyHead').css({opacity:0});
    $('.LyFtr').css({opacity:0});

    this.articleID = ID;

    history.pushState(null,null,'/?type=' + 'single' + '&paged=' + ID);
    Store.PageControl.getParam();

    // ページタイプを更新
    Store.pagetype = 'article';

    this.replaceState({
      pageType: 'single',
      pageID: this.articleID
    });

  },
  tagClick( slug ){
    $('.LyHead').css({opacity:0});
    $('.LyFtr').css({opacity:0});

    this.articleID = slug;

    history.pushState(null,null,'/?type=' + 'tag' + '&paged=' + slug);
    Store.PageControl.getParam();

    this.replaceState({
      pageType: 'tag',
      pageID: this.articleID
    });

  },
  navClick( cat ){
    Store.pagetype = 'category';
    history.pushState(null,null,'/?type=' + 'category' + '&name=' + cat);
    Store.PageControl.getParam();

    this.replaceState({
      pageType: 'category',
      pageID: cat
    });
  },
  backTop(){
    Store.pagetype = 'index';
    this.replaceState({
      pageType: 'index'
    });
    history.pushState(null,null,'/');
    Store.PageControl.getParam();
  },

  componentDidUpdate(){

    //画面遷移後は必ずヘッダーをリセット
    $('.LyHead').removeClass('ExFixed');

    //twitter用JSをロード
    //$('body').append('<blockquote class="twitter-tweet" data-lang="ja"><p lang="ja" dir="ltr">婦人のHPいまだに僕がテキストエディタで更新してるので、誰かスマホ対応に作り変えてください！！</p>&mdash; 佐藤望 (@nozomu_sato) <a href="https://twitter.com/nozomu_sato/status/742667649801289729">2016年6月14日</a></blockquote><script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script>');
  },
  changeMeta(){
    $('meta[name=description]').attr("content", 'Indoor LivingはArtek、広松木工、VitraなどのインテリアからFUJIFILM X-E1で撮影した写真までライフスタイルにスポットを当てたブログです');
    $("title").text('Indoor Living');
  },
  render(){
      if( this.state.pageType == 'index' ){
        this.changeMeta();
        return (
          <div>
          <Gnav pageType={this.state.pageType} backTop={this.backTop} navClick={this.navClick} />
          <Mainvisual thumbClick={this.thumbClick} />
          <ArticleList thumbClick={this.thumbClick}/>
          </div>
        )
      }else if( this.state.pageType == 'single' ){
        return (
          <div>
          <Gnav pageType={this.state.pageType} backTop={this.backTop} navClick={this.navClick} />
          <Single update={this.state.update} articleID={this.state.pageID} thumbClick={this.thumbClick} tagClick={this.tagClick}  />
          </div>
        )
      }else if( this.state.pageType == 'category' ){
        return (
          <div>
          <Gnav pageType={this.state.pageType} backTop={this.backTop} navClick={this.navClick} />
          <CategoryList thumbClick={this.thumbClick}/>
          </div>
        )
      }else if( this.state.pageType == 'tag' ){
        return (
          <div>
          <Gnav pageType={this.state.pageType} backTop={this.backTop} navClick={this.navClick} />
          <TagList thumbClick={this.thumbClick}/>
          </div>
        )
      }else{
        return false;
      }

  }
});



ReactDOM.render(
  <Page />,
  document.getElementById('Main')
);

module.exports = Page;
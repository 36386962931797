var Store = require('./store-article');
var Pager = require('./comp-pager.jsx');
var CntsThumb = require('../pageFncs/cntsThumb.js');

var TagList = React.createClass({
  loadFlag: true,

  getDefaultProps(){
    return {
      actionType:"tag"
    }
  },
  getInitialState(){
    return {
      nowPage: 0,
      maxPage: [],
      name: Store.PageControl.paramObjs.name,
      article: [],
      catSlug: '',
      catName: ''
    }
  },
  componentWillMount(){
    //console.log('CATcomponentWillMount');
    this.loadAction();
  },
  loadAction(){
    //console.log('CATloadAction');
    //console.log(Store.PageControl.paramObjs.name);

    this.loadFlag = false;

    if( Store.gnav.data === null ){
      this.actionCreator( Store.PageControl.paramObjs.paged, Store.PageControl.paramObjs.name, [ this.props.actionType, 'gnav'] );
    }else{
      this.actionCreator( Store.PageControl.paramObjs.paged, Store.PageControl.paramObjs.name, [ this.props.actionType ] );
    }

  },
  componentWillReceiveProps(){
    //console.log('CATcomponentWillReceiveProps');
    this.loadAction();
  },
  actionCreator( page, name, comps ){

    Store.addSubscribe({
      actionType: this.props.actionType,
      callback: this.dataloaded
    });

    Store.dispatcher.action.create({
      actionType: this.props.actionType,
      page: page,
      name: name,
      callback: this.dataloaded,
      requireComps: comps
    });
  },
  dataloaded(){


    this.loadFlag = true;

    var _countArray = [];

    Store.removeSubscribe({
      actionType: this.props.actionType
    });

    
    for (var i = 0; i < Store.tag.data.page.maxPage; i++) {
      _countArray.push(i);
    }

    this.imgLoading( Store.tag.data.article ).then((e)=>{
      if( this.isMounted() ){
        this.replaceState({
          nowPage: Store.tag.data.page.nowPage,
          maxPage: _countArray,
          article: Store.tag.data.article,
          catSlug: Store.tag.data.slug,
          catName: Store.tag.data.name
        });
      }
    });

  },

  shouldComponentUpdate(){

    if( this.loadFlag ){
      return true;
    }else{
      return false;
    }

  },

  componentDidUpdate(){

    if( !this.loadFlag ){
      return false;
    }

    if( Store.location === 'PRO' ){
      //GAにpageviewを送信
      ga('send', {
        hitType: 'pageview',
        page: '?' + location.href.split('?')[1],
        title: this.state.catName + 'タグ一覧'
      });
    }

    //アップデート完了後にローディングを非表示
    Store.LoadControl.hidden();

    this.loadFlag = false;

    this.changeMeta();

    this.first = false;

    CntsThumb.init(Store.Layout);

    $('.MdCntsThumb01 a').on('click', (e)=>{
      e.preventDefault();
    });
  },
  changeMeta(){
    $('meta[name=description]').attr("content", 'タグ' + this.state.catName + 'の記事一覧です');
    $("title").text(this.state.catName + ' | Indoor Living');
  },
  pagerClick( e ){

    e.preventDefault();

    var _num = e.target.dataset.num;

    if( _num === '1'){
      _num = 0;
    }

    if( this.state.nowPage === _num ) {
      return false;
    }else{
      this.actionCreator( _num, Store.PageControl.paramObjs.name, [ this.props.actionType ]);
      history.pushState(null,null,'/?type=' + Store.PageControl.paramObjs.type + '&paged=' + e.target.dataset.num + '&name=' + Store.PageControl.paramObjs.name);
      Store.PageControl.getParam();
    }

  },

  imgLoading( data ){
    var counter = 0,
    d = new $.Deferred();

    data.map((res, index)=>{
      let img = new Image();

      $(img).on('load', ()=>{
        counter = counter + 1;
        if( counter == data.length ){
          d.resolve( counter );
        }
      });

      $(img).attr('src', res.thumb);

    });

    return d.promise();
  },

  thumbClick( ID ){
    this.props.thumbClick(ID);
  },
  render(){

    //console.log('LIrender');

    if( !this.loadFlag ){
      return false;
    }else{
      
      var article = this.state.article.map((res, i)=>{
        return (
            <section key={i} className="MdCntsThumb01"><a onClick={this.thumbClick.bind(this, res.ID)} href={'?type=single&paged=' + res.slug}>
                <p className="mdImg"><img src={res.thumb} /></p>
                <div className="mdInfoClm">
                  <div className="mdClm01">
                    <p className="mdCat">{res.category}</p>
                  </div>
                  <div className="mdClm02">
                    <p className="mdDate">{res.date}</p>
                  </div>
                </div>
                <div className="mdInfoInBox">
                  <h2 className="mdTtl">{res.title}</h2>
                  <p className="mdTxt">{res.content}</p>
                </div>
                <p className="mdIcn"><span className="icon-icon04"></span></p>
                <div className="mdCover">
                  <p className="mdTxt">Read More</p>
                </div></a>
            </section>
          );
      });

      return (
        <section>
        <div className="MdCatImg01">
          <p className="mdImg ExTag">Tag</p>
        </div>
        <h1 className="MdHdgCmn01"><span>{'#'+this.state.catName}</span></h1>
        <div className="LyCntsList">
        {article}
        </div>
        <Pager pagerClick={this.pagerClick} max={this.state.maxPage} stay={this.state.nowPage} />
        </section>
      );
    }
  }
});

module.exports = TagList;
var app = app || {};

app.single = {
  init(){
    this.hdg = $('.mdCms h2');
    this.aside = $('.mdAside');
    this.asideHeight = this.aside.height();
    this.asideLink = $('.MdListAnc01 li');
    this.mv = $('.MdMvSingle01');
    this.cntsBody = $('.MdMainSingle01');
    this.cntsBodyHeight;
    this.mvTop;
    this.hdgPos = [];
    this.coverImg = $('.MdMvSingle01 img');
    this.mainImgs = $('.mdMain img');
    this.arrayImgs = [];
    this.arrayImgsTop = [];
    this.mainImgsLength = this.mainImgs.length;
    this.imgCount = 0;
    this.imgLoadFlag = false;
    this.adjust = 0;

    this.coverImgLoading();

    if( this.mainImgs.length > 0 ){
      this.imgLoading();
    }else{
      this.setEvnt();
    }

    // デバイスによってヘッダーの高さを決める
    if( $('body').hasClass('LySp') ){
      this.adjust = 60;
    }else{
      this.adjust = 100;
    }

  },
  headerControl(scrollTop){


  },
  coverImgLoading(){
    // カバーイメージの読み込みが終わったらフェードイン
    let img = $('<img>');

    img.load(()=>{
      setTimeout(()=>{
        TweenLite.to(
          this.coverImg, 1,
          {opacity: 1, ease:Power2.easeOut}
        );
      }, 500);
    });

    img.attr('src',this.coverImg.attr('src'));
  },
  imgLoading(){

    this.mainImgs.each((i, elm)=>{
        let _img = $('<img>');

        _img.load(()=>{
          this.arrayImgs.push(this.imgCount);
          this.imgCount = this.imgCount + 1;

          if( this.imgCount === this.mainImgsLength ){
            this.setEvnt();
          }
        });

        _img.attr('src',$(elm).attr('src'));
    });

  },
  setEvnt(){

    this.cntsBodyHeight = this.cntsBody.height();
    this.mvTop = this.mv.height() - 40;

    // 各画像の位置を配列に追加
    this.mainImgs.each((i,elm)=>{
      this.arrayImgsTop.push($(elm).offset().top);
    });

    this.hdg.each(( i, elm )=>{
      $(elm).attr('id', 'hdg' + ( i + 1) );
      this.hdgPos.push($(elm).position().top - 200);
    });

    this.asideLink.each(( i, elm )=>{

      $(elm).on('click', (e)=>{
        e.preventDefault();
        TweenLite.to( window, 1,
          {scrollTo:{y: this.hdgPos[i]}, ease:Power2.easeOut});
      });
    });

    $(window).on('scroll', ()=>{

      if( $(window).scrollTop() > this.mvTop + this.cntsBodyHeight - this.asideHeight ){
        this.aside.css({
          position: 'absolute',
          top: this.mvTop + 40 + this.cntsBodyHeight - this.asideHeight
        });

        this.aside.removeClass('FncFixed');

        return true;
      }

      if( $(window).scrollTop() >= this.mvTop && !this.aside.hasClass('FncFixed') ){
        this.aside.css({
          position: 'fixed',
          top: 80
        });
        this.aside.addClass('FncFixed');
      }else if( $(window).scrollTop() <= this.mvTop && this.aside.hasClass('FncFixed') ){
        this.aside.css({
          position: 'static',
          top: 'auto'
        });
        this.aside.removeClass('FncFixed');
      }

      // 画像の位置までスクロールしたらフェードイン
      for (var i = 0; i < this.arrayImgsTop.length; i++) {
        //console.log($(window).scrollTop(),this.arrayImgsTop[i]);
        //console.log(this.arrayImgsTop[i]);
        if( this.arrayImgsTop.length === 0 ){
          break;
        }
        if( $(window).scrollTop() + $(window).height() >= this.arrayImgsTop[i] + $(this.mainImgs[i]).height() / 2 - this.adjust ){
          TweenLite.to(
            this.mainImgs[i], 1,
            {opacity: 1, ease:Power2.easeOut}
          );

          this.mainImgs.splice( i, 1 );
          this.arrayImgsTop.splice( i, 1 );
          //console.log(this.arrayImgsTop);
        }
      }

    });
  }
}

module.exports = app.single;
var app = app || {};

app.music = {
  init( callback ){
    this.LyPlayer = $('.LyPlayer');
    this.MdPlayer = $('.MdPlayer');
    this.MdPlayerInner = $('.mdPlayerInner');
    this.Ttl = $('.mdPlayerTtlInner');
    this.BtnOpen;
    this.BtnClose = $('#FncPlayerClose');
    this.BtnMore = $('#FncPlayMore');
    this.Header = $('.LyHead');
    this.Footer = $('.LyFtr');
    this.callback = callback;
    this.scrollTopOrigin;
    this.clickEventName;
    this.Layout;
    this.Timer;


    //PC or SP判定
    if( $('body').hasClass('LySP') ){
      this.Layout = 'SP';
      this.BtnOpen = $('#FncPlayerOpenSP');
      this.clickEventName = 'touchstart';


    }else{
      this.Layout = 'PC';
      this.BtnOpen = $('#FncPlayerOpenPC');
      this.clickEventName = 'click';
      // オープンボタンのポジションをセット
      TweenMax.set(this.BtnOpen, {position:'fixed',top: 'auto', bottom: -10});
    }

    this.viewControl();
    //this.setBtnPosition();
    this.btnPosition();
  },
  viewPlayer(){
    var d = new $.Deferred;

    if( this.Layout === 'PC' ){
      TweenMax.set(this.MdPlayer, {opacity:0, display:'block', y: $(window).height()});

      TweenMax.to(this.MdPlayer, 0.5, 
        {
          opacity: 1,
          y: 0,
          ease: Power3.easeOut,
          onComplete: ()=>{
            d.resolve();
          }
        }
      );

      return d.promise();
    }else if( this.Layout === 'SP' ){

      TweenMax.set(this.MdPlayer, {opacity:0, display:'block', x: -$(window).width()});

      TweenMax.to(this.MdPlayer, 0.5, 
        {
          opacity: 1,
          x: 0,
          ease: Power3.easeOut,
          onComplete: ()=>{
            d.resolve();
          }
        }
      );

      return d.promise();
    }
  },
  hidePlayer(){
    var d = new $.Deferred;

    if( this.Layout === 'PC' ){
      TweenMax.to(this.MdPlayer, 0.5, 
        {
          opacity: 0,
          y: $(window).height(),
          ease: Power3.easeOut,
          onComplete: ()=>{
            TweenMax.set(this.MdPlayer, {opacity:0, display:'none'});
            d.resolve();
          }
        }
      );

      return d.promise();
    }else if( this.Layout === 'SP' ){
      TweenMax.set(this.LyPlayer, { x: 0});
      TweenMax.to(this.LyPlayer, 0.5, 
        {
          x: -$(window).width(),
          ease: Power3.easeOut,
          onComplete: ()=>{
            d.resolve();
          }
        }
      );

      return d.promise();
    }


  },
  viewBg(){
    var d = new $.Deferred;

    if( this.Layout === 'PC' ){
      TweenMax.set(this.LyPlayer, {opacity:0, display:'block'});
      TweenMax.to(this.LyPlayer, 0.8, 
        {
          opacity: 1, 
          ease: Power3.easeOut,
          onComplete: ()=>{
            d.resolve();
          }
        }
      );

    }else if( this.Layout === 'SP' ){
      TweenMax.set(this.LyPlayer, {opacity:1, display:'block'});
      d.resolve();
    }

    return d.promise();
  },
  hideBg(){
    var d = new $.Deferred;

    if( this.Layout === 'PC' ){
      TweenMax.to(this.LyPlayer, 0.5, 
        {
          opacity: 0, 
          ease: Power3.easeOut,
          onComplete: ()=>{
            TweenMax.set(this.LyPlayer, {display:'none'});
            d.resolve();
          }
        }
      );
    }else if( this.Layout === 'SP' ){
      this.LyPlayer.removeAttr('style');
      this.MdPlayer.removeAttr('style');
      d.resolve();
    }

    return d.promise();
  },
  setBtnPosition(){
    if( this.Layout === 'PC' ){
      if( $(window).scrollTop() +$(window).height() > this.Footer.offset().top){
        TweenMax.set(this.BtnOpen, {position:'absolute',top: -60, bottom: 'auto'});
      }else{
        TweenMax.set(this.BtnOpen, {position:'fixed',top: 'auto', bottom: -10});
      }
    }
  },
  btnPosition(){
    $(window).on('scroll', ()=>{
      if( this.Layout === 'PC' ){
        clearTimeout( this.Timer );
        this.Timer = setTimeout(()=>{
          this.setBtnPosition();
        },10);
      }
    });
  },
  viewControl(){
    
    this.BtnOpen.on(this.clickEventName,(e)=>{
      e.preventDefault();

      this.LyPlayer.addClass('FncView');

      this.scrollTopOrigin = $(window).scrollTop();

      TweenMax.set($('#Wrapper'), { top: -$(window).scrollTop(), position:'fixed',width:'100%' });

      this.viewBg()
      .then(()=>{
        return this.viewPlayer();
      })
      .then(()=>{

        // SP時のレイアウト調整
        if( this.Layout === 'SP' ){
          TweenMax.set(this.MdPlayerInner, {
            height: $(window).height() - this.Ttl.outerHeight() -20
          });
        }

        this.MdPlayer.removeAttr('style');
        this.MdPlayer.addClass('FncView');

        if( $('.mdPlayerIframe').length == 0 ){
          this.callback();
        }
      });
    });

    if( this.Layout === 'SP' ){
      this.BtnOpen.on('click', (e)=>{
        e.preventDefault();
      });
    }

    this.BtnMore.on(this.clickEventName, (e)=>{
      e.preventDefault();
    });

    if( this.Layout === 'SP' ){
      this.BtnMore.on('click', (e)=>{
        e.preventDefault();
      });
    }

    this.BtnClose.on(this.clickEventName, (e)=>{
      e.preventDefault();

      this.hidePlayer()
      .then(()=>{
        return this.hideBg();
      })
      .then(()=>{

        TweenMax.set($('#Wrapper'), { top: 'auto', position:'static',width:'100%' });

        $(window).scrollTop( this.scrollTopOrigin );

        if( this.Layout === 'PC' && $(window).scrollTop() > 45 ){
          this.Header.addClass('ExFixed');
        }

        this.LyPlayer.removeClass('FncView');
        
        this.setBtnPosition();

      });
    });

    if( this.Layout === 'SP' ){
      this.BtnClose.on('click', (e)=>{
        e.preventDefault();
      });
    }

    this.BtnOpen.hover(
      ()=>{
        this.BtnOpen.addClass('FncHover');
        this.BtnOpen.removeClass('FncOut');
      },
      ()=>{
        this.BtnOpen.addClass('FncOut');
        this.BtnOpen.removeClass('FncHover');
      }
    );
  },
  setScroll(offset){
    var height = 0;
    if( offset <= 3 ){
      return false;
    }

    for (var i = 0; i < offset - 3; i++) {
      height = height + $('.mdPlayerItem').eq( i ).outerHeight();
    }

    var elm = $('.mdPlayerItem').eq( offset - 3 ),
        targetTop = elm.position().top;

    TweenLite.to( this.MdPlayerInner, 0.5, {scrollTo:{y: height}});
  }

}

module.exports = app.music;
var Store = require('./store-article');
var Slider = require('../pageFncs/slider.js');

var Mainvisual = React.createClass({
  first: true,
  getDefaultProps(){
    return {
      actionType:"mainvisual"
    }
  },
  getInitialState(){
    return {
      page: 1,
      mainvisual: []
    }
  },
  componentWillMount(){
    //console.log('MVcomponentWillMount');
    this.loadAction();
    
  },

  loadAction(){
    //console.log('MVloadAction');
    if( Store.mainvisual.data === null && Store.gnav.data === null ){
      //console.log('mainvisual first load');
      this.actionCreator( [ this.props.actionType, 'list', 'gnav'] );
    }else if( Store.mainvisual.data === null && Store.gnav.data !== null ){
      //console.log('mainvisual un load');
      this.actionCreator( [ this.props.actionType, 'list'] );
    }else{
      //console.log('secound');
      this.replaceState({ mainvisual: Store.mainvisual.data });

    }
  },

  // componentWillReceiveProps(){
  //   console.log('MVcomponentWillReceiveProps');
  // },
  actionCreator( comps ){
    //console.log('MVactionCreator');

    Store.addSubscribe({
      actionType: this.props.actionType,
      callback: this.dataloaded
    });

    Store.dispatcher.action.create({
      actionType: this.props.actionType,
      callback: this.dataloaded,
      requireComps: comps
    });
  },
  dataloaded(){
    //console.log('MVdataloaded');
    Store.removeSubscribe({
      actionType: this.props.actionType
    });

    if( this.isMounted() ){
      this.replaceState({ mainvisual: Store.mainvisual.data });
    }
  },
  imgLoading( data ){
    var counter = 0,
        d = new $.Deferred();

    data.map((res, index)=>{
      let img = new Image();

      $(img).on('load', ()=>{
        counter = counter + 1;
        if( counter == data.length ){
          d.resolve(counter);
        }
      });

      $(img).attr('src', res.thumb);

    });

    return d.promise();

  },

  componentDidUpdate(){

    this.setSlider();
  },
  componentWillUnmount(){
    //console.log('unmount');
    Slider.unmount();
  },
  shouldComponentUpdate(){

    if( $('.MdSlideContianer').length === 0 ){
      return true;
    }else{
      return false;
    }
    
  },
  setSlider(){
    if( Store.Layout == 'PC' ){
      Slider.unmount();
      Slider.init();
      $('.mdSlideListImg li').each((i, elm)=>{
        $(elm).find('a').eq(0).on('click', (e)=>{
          e.preventDefault();
        });
      });
    }

    if( Store.Layout == 'SP' ){
      $('.mdSlideListImg').slick({
        infinite: true,
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1
      });

      $('.slick-slide').each((i, elm)=>{
        $(elm).find('a').eq(0).on('click', (e)=>{
          e.preventDefault();
        });
      });
    }
  },
  componentDidMount(){
    this.setSlider();
  },
  thumbClick( ID ){
    //console.log(ID);
    this.props.thumbClick(ID);
  },
  render(){
    //console.log('MVrendar');

    var about = ()=>{
      return(
        <section className="MdAbout">
          <h2 className="MdHdgCmn01"><span>About</span></h2>
          <div className="mdLayout">
           <div className="mdImg"><p></p></div>
           <div className="mdTxt">
            <p>Indoor LivingはArtek（アルテック）、広松木工、Vitra（ヴィトラ）などのインテリアからFUJIFILM X-E1で撮影した写真、果ては音楽レビューまで、ライフスタイルに雑多にスポットを当てたブログです。</p>
            <p>自分用のログ、そう多くはないであろう同じ趣味を持った人への情報発信、それに加えてフロントエンドエンジニアとしての実験の場としてもこのブログを活用しています。</p>
            <p>とりあえずReactかAngularやろーってなったんですけど、その時点でAngular2が出ていなかったこともありReactを選択しました。ということがあってこのブログは意味もなくSPAになっているのでした。</p>
            <p>いわゆるHistory APIでページ遷移を検出してクエリーに基づいてAPIにリクエストを送ります。そしてAPIから返されるJSONデータをReactで描画という流れです。</p>
            <p>ブログ自体はwordpressで管理しているので、APIはwordpressで適当に作ってあります。サーバサイドまで範囲を広げてRailsとかでAPI作れればよかったんですけど、それはまた次回ということで。</p>
            <p>何かお気付きの点があれば<a href="/wp/contact/">Contact</a>からお問い合わせくださいませ。</p>
           </div>
          </div>
        </section>
      );
    }


    if(this.state.mainvisual.length === 0){
      return false;
    }else{
      if( Store.Layout == 'PC' ){
        Slider.unmount();

        var mvArray = [];
        for (var i = 0; i < 3; i++) {
          for (var j = 0; j < this.state.mainvisual.length; j++) {
            mvArray.push(this.state.mainvisual[j]);
          }
        }

        var imgs = mvArray.map((res, index)=>{
          return (
            <li key={'thumb'+index}>
              <a onClick={this.thumbClick.bind(this, res.ID)} href={'?type=single&paged=' + res.ID}>
                <p className="mdSlideCat">{res.category}</p>
                <p className="mdSlideTtl"><span>{res.title}</span></p>
                <p className="mdSlideImg"><img src={res.thumb} /></p>
              </a>
            </li>
            );
        });

        var pager = this.state.mainvisual.map((res, index)=>{
          return (
            <li key={index}><a href="#" className="icon-icon01"></a></li>
            );
        });

        return (
          <div>
            <div key={this.mainvisualID} className="MdSlideContianer">
              <ul className="mdSlideListImg">
              {imgs}
              </ul>
              <ul className="mdSlideListPager">
              {pager}
              </ul>
              <ul className="mdSlideListBtn">
                <li className="mdSlideListBtnBack"><a href="#" className="icon-icon02"></a></li>
                <li className="mdSlideListBtnNext"><a href="#" className="icon-icon04"></a></li>
              </ul>
            </div>
            {about()}
          </div>
        );
      }

      if( Store.Layout == 'SP' ){

        var mvArray = [];
        for (var i = 0; i < 1; i++) {
          for (var j = 0; j < this.state.mainvisual.length; j++) {
            mvArray.push(this.state.mainvisual[j]);
          }
        }

        var imgs = mvArray.map((res, index)=>{
          return (
            <li className="mdList" key={'thumb'+index}>
              <a onClick={this.thumbClick.bind(this, res.ID)} href={'?type=single&paged=' + res.ID}>
                <p className="mdSlideCat">{res.category}</p>
                <p className="mdSlideTtl"><span>{res.title}</span></p>
                <p className="mdSlideImg"><img src={res.thumb} /></p>
              </a>
            </li>
            );
        });

        return (
          <div>
            <div className="MdSlideContianerSP">
              <ul className="mdSlideListImg">{imgs}</ul>
            </div>
            {about()}
          </div>
        );
      }

    }
  }
});

module.exports = Mainvisual;
var app = app || {};

app.form = {
  init(){
    var hash = window.location.hash;

    $('.wpcf7-form').attr('action', '/wp/contact/')

  }
}

module.exports = app.form;